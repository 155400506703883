import { api as generatedApi } from "./KnowledgeBase.generated"
import { api as restApi } from "@/app/services/rest/api"

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: ["Category", "Article", "FurtherReading"],
  endpoints: {
    GetCategoryList: {
      providesTags: () => [{ type: "Category", id: "LIST" }],
    },
    GetCategoryTitle: {
      providesTags: (result, error, arg) => [{ type: "Category", id: arg.id }],
    },
    UpdateCategoryWeight: {
      invalidatesTags: (result, error, arg) => [
        { type: "Category", id: "LIST" },
        { type: "Category", id: arg.id },
      ],
    },
    CreateCategory: {
      invalidatesTags: () => [{ type: "Category", id: "LIST" }],
    },
    UpdateCategory: {
      invalidatesTags: (result, error, arg) => [
        { type: "Category", id: "LIST" },
        { type: "Category", id: arg.id },
      ],
    },
    DeleteCategory: {
      invalidatesTags: (result, error, arg) => [
        { type: "Category", id: "LIST" },
        { type: "Category", id: arg.id },
      ],
    },
    GetFurtherReading: {
      providesTags: (result, error, arg) => [
        { type: "FurtherReading", id: "LIST" },
      ],
    },
    GetArticles: {
      providesTags: () => [{ type: "Article", id: "LIST" }],
    },
    GetHomeArticles: {
      providesTags: () => [{ type: "Article", id: "LIST" }],
    },
    GetArticle: {
      providesTags: (result, error, arg) => [{ type: "Article", id: arg.id }],
    },
    CreateArticle: {
      invalidatesTags: () => [
        { type: "Article", id: "LIST" },
        { type: "Category", id: "LIST" },
      ],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled

        dispatch(
          restApi.util.invalidateTags([{ type: "FurtherReading", id: "LIST" }]),
        )
      },
    },
    UpdateArticleContents: {
      invalidatesTags: (result, error, arg) => [
        { type: "Article", id: arg.id },
      ],
    },
    UpdateArticleWeight: {
      invalidatesTags: (_, __, args) => [
        { type: "Category", id: "LIST" },
        { type: "Article", id: "LIST" },
        { type: "Article", id: args.id },
      ],
    },
    DeleteArticle: {
      invalidatesTags: (_, __, args) => [
        { type: "Category", id: "LIST" },
        { type: "Article", id: "LIST" },
        { type: "FurtherReading", id: "LIST" },
        { type: "Article", id: args.id },
      ],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled

        dispatch(
          restApi.util.invalidateTags([{ type: "FurtherReading", id: "LIST" }]),
        )
      },
    },
  },
})

export const {
  useGetCategoryListQuery,
  useGetCategoryTitleQuery,
  useUpdateCategoryWeightMutation,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useGetFurtherReadingQuery,
  useGetArticlesQuery,
  useGetArticleQuery,
  useCreateArticleMutation,
  useUpdateArticleContentsMutation,
  useUpdateArticleWeightMutation,
  useDeleteArticleMutation,
  useGetHomeArticlesQuery,
} = api

export const {
  GetCategoryList,
  GetCategoryTitle,
  UpdateCategoryWeight,
  CreateCategory,
  UpdateCategory,
  DeleteCategory,
  GetFurtherReading,
  GetArticles,
  GetArticle,
  CreateArticle,
  UpdateArticleContents,
  UpdateArticleWeight,
  DeleteArticle,
  GetHomeArticles,
} = api.endpoints
