/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../app/services/graphql/types.generated';

import { api } from '@/app/services/graphql/api';
export type GetCategoryListQueryVariables = Types.Exact<{
  is_visible?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  sub_category_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetCategoryListQuery = { __typename?: 'Query', category?: Array<{ __typename?: 'CategoryOutputType', id: string, title: string, icon: string, description: string, weight?: number, is_visible: boolean, sub_category_id?: number, articles: Array<{ __typename?: 'ArticleOutputType', id: string, title: string, weight?: number, is_visible: boolean }> }> };

export type GetCategoryTitleQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetCategoryTitleQuery = { __typename?: 'Query', category?: Array<{ __typename?: 'CategoryOutputType', id: string, title: string, icon: string }> };

export type UpdateCategoryWeightMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  weight: Types.Scalars['Int']['input'];
}>;


export type UpdateCategoryWeightMutation = { __typename?: 'Mutation', update_category?: { __typename?: 'UpdateCategory', ok?: boolean } };

export type CreateCategoryMutationVariables = Types.Exact<{
  title: Types.Scalars['String']['input'];
  description: Types.Scalars['String']['input'];
  icon: Types.Scalars['String']['input'];
  is_visible?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  sub_category_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  weight?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', create_category?: { __typename?: 'CreateCategory', ok?: boolean } };

export type UpdateCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  title?: Types.InputMaybe<Types.Scalars['String']['input']>;
  description?: Types.InputMaybe<Types.Scalars['String']['input']>;
  icon?: Types.InputMaybe<Types.Scalars['String']['input']>;
  is_visible?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  sub_category_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type UpdateCategoryMutation = { __typename?: 'Mutation', update_category?: { __typename?: 'UpdateCategory', ok?: boolean } };

export type DeleteCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteCategoryMutation = { __typename?: 'Mutation', delete_category?: { __typename?: 'DeleteCategory', ok?: boolean } };

export type GetFurtherReadingQueryVariables = Types.Exact<{
  category_id?: Types.InputMaybe<Types.Scalars['String']['input']>;
  tags?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
}>;


export type GetFurtherReadingQuery = { __typename?: 'Query', article?: Array<{ __typename?: 'ArticleOutputType', id: string, title: string, weight?: number, is_visible: boolean, category?: { __typename?: 'CategoryOutputType', id: string, title: string } }> };

export type GetArticlesQueryVariables = Types.Exact<{
  category_id?: Types.InputMaybe<Types.Scalars['String']['input']>;
  is_visible?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  page_size?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetArticlesQuery = { __typename?: 'Query', article?: Array<{ __typename?: 'ArticleOutputType', id: string, title: string, weight?: number, tags?: string, is_visible: boolean }> };

export type GetHomeArticlesQueryVariables = Types.Exact<{
  weight?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetHomeArticlesQuery = { __typename?: 'Query', article?: Array<{ __typename?: 'ArticleOutputType', id: string, title: string, description: string, layout_id: string, content: string, tags?: string, is_visible: boolean, category_id: number, category?: { __typename?: 'CategoryOutputType', title: string, sub_category_id?: number } }> };

export type GetArticleQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetArticleQuery = { __typename?: 'Query', article?: Array<{ __typename?: 'ArticleOutputType', id: string, title: string, description: string, layout_id: string, content: string, tags?: string, is_visible: boolean }> };

export type CreateArticleMutationVariables = Types.Exact<{
  category_id: Types.Scalars['String']['input'];
  title: Types.Scalars['String']['input'];
  content: Types.Scalars['String']['input'];
  description: Types.Scalars['String']['input'];
  layout: Types.Scalars['String']['input'];
  tags: Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CreateArticleMutation = { __typename?: 'Mutation', create_article?: { __typename?: 'CreateArticle', ok?: boolean, article?: { __typename?: 'ArticleOutputType', id: string } } };

export type UpdateArticleContentsMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  content: Types.Scalars['String']['input'];
  tags?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  title?: Types.InputMaybe<Types.Scalars['String']['input']>;
  description?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdateArticleContentsMutation = { __typename?: 'Mutation', update_article?: { __typename?: 'UpdateArticle', ok?: boolean } };

export type UpdateArticleWeightMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  weight: Types.Scalars['Int']['input'];
}>;


export type UpdateArticleWeightMutation = { __typename?: 'Mutation', update_article?: { __typename?: 'UpdateArticle', ok?: boolean } };

export type DeleteArticleMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteArticleMutation = { __typename?: 'Mutation', delete_article?: { __typename?: 'DeleteArticle', ok?: boolean } };


export const GetCategoryListDocument = `
    query GetCategoryList($is_visible: Boolean, $sub_category_id: Int) {
  category(filters: {is_visible: $is_visible, sub_category_id: $sub_category_id}) {
    id
    title
    icon
    description
    weight
    is_visible
    sub_category_id
    articles {
      id
      title
      weight
      is_visible
    }
  }
}
    `;
export const GetCategoryTitleDocument = `
    query GetCategoryTitle($id: Int!) {
  category(filters: {id: $id}) {
    id
    title
    icon
  }
}
    `;
export const UpdateCategoryWeightDocument = `
    mutation UpdateCategoryWeight($id: Int!, $weight: Int!) {
  update_category(id: $id, weight: $weight) {
    ok
  }
}
    `;
export const CreateCategoryDocument = `
    mutation CreateCategory($title: String!, $description: String!, $icon: String!, $is_visible: Boolean, $sub_category_id: Int, $weight: Int) {
  create_category(
    title: $title
    description: $description
    icon: $icon
    is_visible: $is_visible
    sub_category_id: $sub_category_id
    weight: $weight
  ) {
    ok
  }
}
    `;
export const UpdateCategoryDocument = `
    mutation UpdateCategory($id: Int!, $title: String, $description: String, $icon: String, $is_visible: Boolean, $sub_category_id: Int) {
  update_category(
    id: $id
    title: $title
    description: $description
    icon: $icon
    is_visible: $is_visible
    sub_category_id: $sub_category_id
  ) {
    ok
  }
}
    `;
export const DeleteCategoryDocument = `
    mutation DeleteCategory($id: Int!) {
  delete_category(id: $id) {
    ok
  }
}
    `;
export const GetFurtherReadingDocument = `
    query GetFurtherReading($category_id: String, $tags: [String]) {
  article(filters: {category_id: $category_id, tags: $tags}) {
    id
    title
    weight
    is_visible
    category {
      id
      title
    }
  }
}
    `;
export const GetArticlesDocument = `
    query GetArticles($category_id: String, $is_visible: Boolean, $page_size: Int) {
  article(
    filters: {category_id: $category_id, is_visible: $is_visible}
    page_size: $page_size
  ) {
    id
    title
    weight
    tags
    is_visible
  }
}
    `;
export const GetHomeArticlesDocument = `
    query GetHomeArticles($weight: Int) {
  article(filters: {weight: $weight}, sort_by: "created_at", sort_dir: "desc") {
    id
    title
    description
    layout_id
    content
    tags
    is_visible
    category_id
    category {
      title
      sub_category_id
    }
  }
}
    `;
export const GetArticleDocument = `
    query GetArticle($id: Int!) {
  article(filters: {id: $id}) {
    id
    title
    description
    layout_id
    content
    tags
    is_visible
  }
}
    `;
export const CreateArticleDocument = `
    mutation CreateArticle($category_id: String!, $title: String!, $content: String!, $description: String!, $layout: String!, $tags: [String]!) {
  create_article(
    category_id: $category_id
    title: $title
    content: $content
    description: $description
    layout_id: $layout
    tags: $tags
  ) {
    ok
    article {
      id
    }
  }
}
    `;
export const UpdateArticleContentsDocument = `
    mutation UpdateArticleContents($id: Int!, $content: String!, $tags: [String], $title: String, $description: String) {
  update_article(
    id: $id
    content: $content
    tags: $tags
    title: $title
    description: $description
  ) {
    ok
  }
}
    `;
export const UpdateArticleWeightDocument = `
    mutation UpdateArticleWeight($id: Int!, $weight: Int!) {
  update_article(id: $id, weight: $weight) {
    ok
  }
}
    `;
export const DeleteArticleDocument = `
    mutation DeleteArticle($id: Int!) {
  delete_article(id: $id) {
    ok
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetCategoryList: build.query<GetCategoryListQuery, GetCategoryListQueryVariables | void>({
      query: (variables) => ({ document: GetCategoryListDocument, variables })
    }),
    GetCategoryTitle: build.query<GetCategoryTitleQuery, GetCategoryTitleQueryVariables>({
      query: (variables) => ({ document: GetCategoryTitleDocument, variables })
    }),
    UpdateCategoryWeight: build.mutation<UpdateCategoryWeightMutation, UpdateCategoryWeightMutationVariables>({
      query: (variables) => ({ document: UpdateCategoryWeightDocument, variables })
    }),
    CreateCategory: build.mutation<CreateCategoryMutation, CreateCategoryMutationVariables>({
      query: (variables) => ({ document: CreateCategoryDocument, variables })
    }),
    UpdateCategory: build.mutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>({
      query: (variables) => ({ document: UpdateCategoryDocument, variables })
    }),
    DeleteCategory: build.mutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>({
      query: (variables) => ({ document: DeleteCategoryDocument, variables })
    }),
    GetFurtherReading: build.query<GetFurtherReadingQuery, GetFurtherReadingQueryVariables | void>({
      query: (variables) => ({ document: GetFurtherReadingDocument, variables })
    }),
    GetArticles: build.query<GetArticlesQuery, GetArticlesQueryVariables | void>({
      query: (variables) => ({ document: GetArticlesDocument, variables })
    }),
    GetHomeArticles: build.query<GetHomeArticlesQuery, GetHomeArticlesQueryVariables | void>({
      query: (variables) => ({ document: GetHomeArticlesDocument, variables })
    }),
    GetArticle: build.query<GetArticleQuery, GetArticleQueryVariables>({
      query: (variables) => ({ document: GetArticleDocument, variables })
    }),
    CreateArticle: build.mutation<CreateArticleMutation, CreateArticleMutationVariables>({
      query: (variables) => ({ document: CreateArticleDocument, variables })
    }),
    UpdateArticleContents: build.mutation<UpdateArticleContentsMutation, UpdateArticleContentsMutationVariables>({
      query: (variables) => ({ document: UpdateArticleContentsDocument, variables })
    }),
    UpdateArticleWeight: build.mutation<UpdateArticleWeightMutation, UpdateArticleWeightMutationVariables>({
      query: (variables) => ({ document: UpdateArticleWeightDocument, variables })
    }),
    DeleteArticle: build.mutation<DeleteArticleMutation, DeleteArticleMutationVariables>({
      query: (variables) => ({ document: DeleteArticleDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCategoryListQuery, useLazyGetCategoryListQuery, useGetCategoryTitleQuery, useLazyGetCategoryTitleQuery, useUpdateCategoryWeightMutation, useCreateCategoryMutation, useUpdateCategoryMutation, useDeleteCategoryMutation, useGetFurtherReadingQuery, useLazyGetFurtherReadingQuery, useGetArticlesQuery, useLazyGetArticlesQuery, useGetHomeArticlesQuery, useLazyGetHomeArticlesQuery, useGetArticleQuery, useLazyGetArticleQuery, useCreateArticleMutation, useUpdateArticleContentsMutation, useUpdateArticleWeightMutation, useDeleteArticleMutation } = injectedRtkApi;

