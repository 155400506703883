/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../app/services/graphql/types.generated';

import { api } from '@/app/services/graphql/api';
export type GetGameQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  organisation_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  user_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sort_by?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort_dir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page_size?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  page_no?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  feedback_status?: Types.InputMaybe<Types.Scalars['String']['input']>;
  game_categories?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  game_genres?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  internal_fav?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  internal_spam?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  is_admin_reviewed?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  assigned_internal_user_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetGameQuery = { __typename?: 'Query', total_count?: number, game?: Array<{ __typename?: 'GameOutputType', id: string, name: string, user_id: number, game_categories?: string, game_genres?: string, icon_url?: string, description: string, organisation_id?: number, feedback_status: string, app_store_ratings?: number, playstore_ratings?: number, completion_percentage?: number, hs_deal_id?: string, internal_spam?: boolean, internal_fav?: boolean, is_admin_reviewed: boolean, assigned_internal_user_id?: number, folders: Array<{ __typename?: 'FolderOutputType', id: string }>, kwalee_sdk_key: Array<{ __typename?: 'KwaleeSdkKeysOutputType', is_kwalee_sdk_integrated: boolean, is_kwalee_sdk_validated: boolean }> }> };

export type GetFeedbackGamesListQueryVariables = Types.Exact<{
  feedback_status?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetFeedbackGamesListQuery = { __typename?: 'Query', game?: Array<{ __typename?: 'GameOutputType', id: string, name: string, feedback_status: string }> };

export type GetGamesCountQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  organisation_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  game_categories?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  game_genres?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  internal_fav?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  internal_spam?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  assigned_internal_user_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetGamesCountQuery = { __typename?: 'Query', total_count?: number, total_feedback_requests?: number, total_active_feedbacks?: number, total_reviewed_games?: number };

export type GetGameDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetGameDetailsQuery = { __typename?: 'Query', game?: Array<{ __typename?: 'GameOutputType', id: string, name: string, description: string, icon_url?: string, additional_info?: string, android_store_link?: string, ios_store_link?: string, game_media_urls?: Array<string>, app_store_ratings?: number, playstore_ratings?: number, hs_deal_id?: string, organisation_id?: number, status?: string, game_stage?: string, feedback_status: string, game_categories?: string, game_genres?: string, assigned_internal_user_id?: number, folders: Array<{ __typename?: 'FolderOutputType', id: string }>, kwalee_sdk_key: Array<{ __typename?: 'KwaleeSdkKeysOutputType', is_kwalee_sdk_integrated: boolean, is_kwalee_sdk_validated: boolean }> }> };

export type GetLatestGamesQueryVariables = Types.Exact<{
  page_size?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetLatestGamesQuery = { __typename?: 'Query', game?: Array<{ __typename?: 'AllGamesOutputType', id?: number, name?: string, icon_url?: string, description?: string, organisation_id?: number }> };

export type GetGameFeedbackAreasQueryVariables = Types.Exact<{
  game_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetGameFeedbackAreasQuery = { __typename?: 'Query', feedback_area?: Array<{ __typename?: 'FeedbackAreaOutputType', id: string, feedback_area_name: string }>, all_feedback_area?: Array<{ __typename?: 'FeedbackAreaOutputType', id: string, feedback_area_name: string }> };

export type GetGameFeedbackThreadsQueryVariables = Types.Exact<{
  game_id: Types.Scalars['Int']['input'];
  sort_by?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort_dir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page_size?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetGameFeedbackThreadsQuery = { __typename?: 'Query', feedback_thread?: Array<{ __typename?: 'FeedbackThreadOutputType', id: string, text: string, title: string, files?: string, created_at: any, feedback_area_id: number }> };

export type GetGameFeedbackChatQueryVariables = Types.Exact<{
  thread_id: Types.Scalars['Int']['input'];
  created_at_range?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page_size?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  page_no?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sort_by?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort_dir?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetGameFeedbackChatQuery = { __typename?: 'Query', feedback_chat_item?: Array<{ __typename?: 'FeedbackChatItemOutputType', id: string, text: string, files?: string, thread_id: number, created_at: any, users?: { __typename?: 'UserOutputType', id: string, name: string, designation?: string, profile_image?: string, user_type: string } }> };

export type GetGameTitleQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetGameTitleQuery = { __typename?: 'Query', game?: Array<{ __typename?: 'GameOutputType', id: string, name: string, guid: string }> };

export type GetGameListFilterQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGameListFilterQuery = { __typename?: 'Query', game?: Array<{ __typename?: 'GameOutputType', icon_url?: string, name: string, id: string, kwalee_sdk_key: Array<{ __typename?: 'KwaleeSdkKeysOutputType', is_kwalee_sdk_validated: boolean }> }> };

export type GetGameTagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGameTagsQuery = { __typename?: 'Query', game_tags?: Array<{ __typename?: 'GameTagsOutputType', name: string, type: string, id: string }> };

export type GetGameStagesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGameStagesQuery = { __typename?: 'Query', game_stage?: Array<{ __typename?: 'GameStageOutputType', id: string, stage_name: string, hs_stage_id?: string }> };

export type GetGamesGridListQueryVariables = Types.Exact<{
  assigned_internal_user_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetGamesGridListQuery = { __typename?: 'Query', game?: Array<{ __typename?: 'GameOutputType', updated_at: any, id: string, name: string, icon_url?: string, game_stage?: string, assigned_internal_user_id?: number, organisation?: { __typename?: 'OrganisationOutputType', name: string } }> };


export const GetGameDocument = `
    query GetGame($id: Int, $organisation_id: Int, $user_id: Int, $sort_by: String, $sort_dir: String, $page_size: Int, $page_no: Int, $search: String, $feedback_status: String, $game_categories: [String], $game_genres: [String], $internal_fav: Boolean, $internal_spam: Boolean, $is_admin_reviewed: Boolean, $assigned_internal_user_id: Int) {
  game(
    filters: {id: $id, organisation_id: $organisation_id, user_id: $user_id, search_name: $search, feedback_status: $feedback_status, game_categories: $game_categories, game_genres: $game_genres, internal_fav: $internal_fav, internal_spam: $internal_spam, is_admin_reviewed: $is_admin_reviewed, assigned_internal_user_id: $assigned_internal_user_id}
    sort_by: $sort_by
    sort_dir: $sort_dir
    page_size: $page_size
    page_no: $page_no
  ) {
    id
    name
    user_id
    game_categories
    game_genres
    icon_url
    description
    organisation_id
    feedback_status
    app_store_ratings
    playstore_ratings
    completion_percentage
    hs_deal_id
    folders {
      id
    }
    kwalee_sdk_key {
      is_kwalee_sdk_integrated
      is_kwalee_sdk_validated
    }
    internal_spam
    internal_fav
    is_admin_reviewed
    assigned_internal_user_id
  }
  total_count: game_total_objects
}
    `;
export const GetFeedbackGamesListDocument = `
    query GetFeedbackGamesList($feedback_status: String) {
  game(filters: {feedback_status: $feedback_status}) {
    id
    name
    feedback_status
  }
}
    `;
export const GetGamesCountDocument = `
    query GetGamesCount($search: String, $organisation_id: Int, $game_categories: [String], $game_genres: [String], $internal_fav: Boolean, $internal_spam: Boolean, $assigned_internal_user_id: Int) {
  total_count: game_total_objects(
    filters: {search_name: $search, organisation_id: $organisation_id, game_categories: $game_categories, game_genres: $game_genres, internal_fav: $internal_fav, internal_spam: $internal_spam, assigned_internal_user_id: $assigned_internal_user_id}
  )
  total_feedback_requests: game_total_objects(
    filters: {search_name: $search, organisation_id: $organisation_id, feedback_status: "requested", game_categories: $game_categories, game_genres: $game_genres, internal_fav: $internal_fav, internal_spam: $internal_spam, assigned_internal_user_id: $assigned_internal_user_id}
  )
  total_active_feedbacks: game_total_objects(
    filters: {search_name: $search, organisation_id: $organisation_id, feedback_status: "active", game_categories: $game_categories, game_genres: $game_genres, internal_fav: $internal_fav, internal_spam: $internal_spam, assigned_internal_user_id: $assigned_internal_user_id}
  )
  total_reviewed_games: game_total_objects(
    filters: {search_name: $search, organisation_id: $organisation_id, game_categories: $game_categories, game_genres: $game_genres, internal_fav: $internal_fav, internal_spam: $internal_spam, is_admin_reviewed: true, assigned_internal_user_id: $assigned_internal_user_id}
  )
}
    `;
export const GetGameDetailsDocument = `
    query GetGameDetails($id: Int!) {
  game(filters: {id: $id}) {
    id
    name
    description
    icon_url
    additional_info
    android_store_link
    ios_store_link
    game_media_urls
    app_store_ratings
    playstore_ratings
    hs_deal_id
    organisation_id
    status
    game_stage
    feedback_status
    game_categories
    game_genres
    assigned_internal_user_id
    folders {
      id
    }
    kwalee_sdk_key {
      is_kwalee_sdk_integrated
      is_kwalee_sdk_validated
    }
  }
}
    `;
export const GetLatestGamesDocument = `
    query GetLatestGames($page_size: Int) {
  game: all_games(sort_by: "created_at", sort_dir: "desc", page_size: $page_size) {
    id
    name
    icon_url
    description
    organisation_id
  }
}
    `;
export const GetGameFeedbackAreasDocument = `
    query GetGameFeedbackAreas($game_id: Int) {
  feedback_area(game_id: $game_id) {
    id
    feedback_area_name
  }
  all_feedback_area: feedback_area {
    id
    feedback_area_name
  }
}
    `;
export const GetGameFeedbackThreadsDocument = `
    query GetGameFeedbackThreads($game_id: Int!, $sort_by: String, $sort_dir: String, $page_size: Int) {
  feedback_thread(
    game_id: $game_id
    sort_by: $sort_by
    sort_dir: $sort_dir
    page_size: $page_size
  ) {
    id
    text
    title
    files
    created_at
    feedback_area_id
  }
}
    `;
export const GetGameFeedbackChatDocument = `
    query GetGameFeedbackChat($thread_id: Int!, $created_at_range: String, $page_size: Int, $page_no: Int, $sort_by: String, $sort_dir: String) {
  feedback_chat_item(
    filters: {thread_id: $thread_id, created_at_range: $created_at_range}
    page_size: $page_size
    page_no: $page_no
    sort_by: $sort_by
    sort_dir: $sort_dir
  ) {
    id
    text
    files
    thread_id
    created_at
    users {
      id
      name
      designation
      profile_image
      user_type
    }
  }
}
    `;
export const GetGameTitleDocument = `
    query GetGameTitle($id: Int!) {
  game(filters: {id: $id}) {
    id
    name
    guid
  }
}
    `;
export const GetGameListFilterDocument = `
    query GetGameListFilter {
  game(sort_dir: "asc", sort_by: "name") {
    icon_url
    name
    id
    kwalee_sdk_key {
      is_kwalee_sdk_validated
    }
  }
}
    `;
export const GetGameTagsDocument = `
    query GetGameTags {
  game_tags {
    name
    type
    id
  }
}
    `;
export const GetGameStagesDocument = `
    query GetGameStages {
  game_stage {
    id
    stage_name
    hs_stage_id
  }
}
    `;
export const GetGamesGridListDocument = `
    query GetGamesGridList($assigned_internal_user_id: Int) {
  game(
    sort_dir: "desc"
    sort_by: "updated_at"
    filters: {assigned_internal_user_id: $assigned_internal_user_id}
  ) {
    updated_at
    id
    name
    icon_url
    game_stage
    assigned_internal_user_id
    organisation {
      name
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetGame: build.query<GetGameQuery, GetGameQueryVariables | void>({
      query: (variables) => ({ document: GetGameDocument, variables })
    }),
    GetFeedbackGamesList: build.query<GetFeedbackGamesListQuery, GetFeedbackGamesListQueryVariables | void>({
      query: (variables) => ({ document: GetFeedbackGamesListDocument, variables })
    }),
    GetGamesCount: build.query<GetGamesCountQuery, GetGamesCountQueryVariables | void>({
      query: (variables) => ({ document: GetGamesCountDocument, variables })
    }),
    GetGameDetails: build.query<GetGameDetailsQuery, GetGameDetailsQueryVariables>({
      query: (variables) => ({ document: GetGameDetailsDocument, variables })
    }),
    GetLatestGames: build.query<GetLatestGamesQuery, GetLatestGamesQueryVariables | void>({
      query: (variables) => ({ document: GetLatestGamesDocument, variables })
    }),
    GetGameFeedbackAreas: build.query<GetGameFeedbackAreasQuery, GetGameFeedbackAreasQueryVariables | void>({
      query: (variables) => ({ document: GetGameFeedbackAreasDocument, variables })
    }),
    GetGameFeedbackThreads: build.query<GetGameFeedbackThreadsQuery, GetGameFeedbackThreadsQueryVariables>({
      query: (variables) => ({ document: GetGameFeedbackThreadsDocument, variables })
    }),
    GetGameFeedbackChat: build.query<GetGameFeedbackChatQuery, GetGameFeedbackChatQueryVariables>({
      query: (variables) => ({ document: GetGameFeedbackChatDocument, variables })
    }),
    GetGameTitle: build.query<GetGameTitleQuery, GetGameTitleQueryVariables>({
      query: (variables) => ({ document: GetGameTitleDocument, variables })
    }),
    GetGameListFilter: build.query<GetGameListFilterQuery, GetGameListFilterQueryVariables | void>({
      query: (variables) => ({ document: GetGameListFilterDocument, variables })
    }),
    GetGameTags: build.query<GetGameTagsQuery, GetGameTagsQueryVariables | void>({
      query: (variables) => ({ document: GetGameTagsDocument, variables })
    }),
    GetGameStages: build.query<GetGameStagesQuery, GetGameStagesQueryVariables | void>({
      query: (variables) => ({ document: GetGameStagesDocument, variables })
    }),
    GetGamesGridList: build.query<GetGamesGridListQuery, GetGamesGridListQueryVariables | void>({
      query: (variables) => ({ document: GetGamesGridListDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetGameQuery, useLazyGetGameQuery, useGetFeedbackGamesListQuery, useLazyGetFeedbackGamesListQuery, useGetGamesCountQuery, useLazyGetGamesCountQuery, useGetGameDetailsQuery, useLazyGetGameDetailsQuery, useGetLatestGamesQuery, useLazyGetLatestGamesQuery, useGetGameFeedbackAreasQuery, useLazyGetGameFeedbackAreasQuery, useGetGameFeedbackThreadsQuery, useLazyGetGameFeedbackThreadsQuery, useGetGameFeedbackChatQuery, useLazyGetGameFeedbackChatQuery, useGetGameTitleQuery, useLazyGetGameTitleQuery, useGetGameListFilterQuery, useLazyGetGameListFilterQuery, useGetGameTagsQuery, useLazyGetGameTagsQuery, useGetGameStagesQuery, useLazyGetGameStagesQuery, useGetGamesGridListQuery, useLazyGetGamesGridListQuery } = injectedRtkApi;

