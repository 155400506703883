import { store } from "@/app/store"
import ProtectedRoute from "@/components/ProtectedRoute"
import { GetArticle } from "@/features/knowledgeBase/KnowledgeBase"
import { CategoryParams } from "@/pages/KnowledgeBase/Category/route"
import i18next from "i18next"
import { lazy } from "react"
import { LoaderFunctionArgs, RouteObject } from "react-router-dom"

export type ArticleParams = CategoryParams & {
  article: string
}

const KnowledgeBaseArticle = lazy(
  () => import("@/pages/KnowledgeBase/Article/KnowledgeBaseArticle"),
)
const KnowledgeBaseArticleView = lazy(
  () => import("@/pages/KnowledgeBase/Article/KnowledgeBaseArticleView"),
)
const KnowledgeBaseArticleCreate = lazy(
  () => import("@/pages/KnowledgeBase/Article/KnowledgeBaseArticleCreate"),
)
const KnowledgeBaseArticleEdit = lazy(
  () => import("@/pages/KnowledgeBase/Article/KnowledgeBaseArticleEdit"),
)

const route: RouteObject[] = [
  {
    path: "/knowledge-base/:category/create",
    element: (
      <ProtectedRoute
        redirectTo="/"
        hasPermission={(state) => state.auth.scope === "internal"}
      >
        <KnowledgeBaseArticleCreate />
      </ProtectedRoute>
    ),
    handle: {
      crumb: () => i18next.t("label.createArticle", { ns: "knowledgeBase" }),
    },
  },
  {
    path: "/knowledge-base/:category/:article/edit",
    element: (
      <ProtectedRoute
        redirectTo="/"
        hasPermission={(state) => state.auth.scope === "internal"}
      >
        <KnowledgeBaseArticleEdit />
      </ProtectedRoute>
    ),
    handle: {
      crumb: () => i18next.t("label.editArticle", { ns: "knowledgeBase" }),
    },
  },
  {
    path: "/knowledge-base/:category/:article",
    element: <KnowledgeBaseArticle />,
    handle: {
      crumb: (data: string) =>
        data ?? i18next.t("label.unknownArticle", { ns: "knowledgeBase" }),
    },
    loader: async ({ params }: LoaderFunctionArgs<ArticleParams>) => {
      let title = null

      // @ts-ignore
      if (!isNaN(params.article)) {
        const parsedParams = { id: parseInt(params.article) }
        const getter = store.dispatch(GetArticle.initiate(parsedParams))
        await getter

        const response = GetArticle.select(parsedParams)(store.getState())

        if (
          (response.data?.article?.length ?? 0) > 0 &&
          response.data!.article![0].title
        ) {
          title = response.data!.article![0].title
        }

        getter.unsubscribe()
      }

      return title
    },
    children: [
      {
        index: true,
        element: <KnowledgeBaseArticleView />,
      },
    ],
  },
]

export default route
